import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

import { Observable } from 'rxjs';
import { CountriesFacade } from './countries/countries.facade';
import { GendersFacade } from './genders/genders.facade';
import { ProcurementCodesFacade } from './procurement-codes/procurement-codes.facade';
import { UsersFacade } from './users/users.facade';
import { TypeCategoryFacade } from './type-category/type-category.facade';
import { OperationsFacade } from './operations/operations.facade';

@Injectable()
export class StockFlowReferenceDataFacade {
  countries$: Observable<SersiSelectListItem[]>;
  genders$: Observable<SersiSelectListItem[]>;
  procurementCodes$: Observable<SersiSelectListItem[]>;
  users$: Observable<SersiSelectListItem[]>;
  typeCategories$: Observable<SersiSelectListItem[]>;
  operations$: Observable<SersiSelectListItem[]>;

  constructor(
    private readonly store: Store,
    private countriesFacade: CountriesFacade,
    private gendersFacade: GendersFacade,
    private procurementCodesFacade: ProcurementCodesFacade,
    private usersFacade: UsersFacade,
    private typeCategoryFacade: TypeCategoryFacade,
    private operationsFacade: OperationsFacade
  ) {
    this.countries$ = this.countriesFacade.items$;
    this.genders$ = this.gendersFacade.items$;
    this.procurementCodes$ = this.procurementCodesFacade.items$;
    this.users$ = this.usersFacade.items$;
    this.typeCategories$ = this.typeCategoryFacade.items$;
    this.operations$ = this.operationsFacade.items$;
  }

  getCountries(): void {
    this.countriesFacade.get();
  }

  getGenders(operationId: string): void {
    this.gendersFacade.get(operationId);
  }

  getProcurementCodes(operationId: string): void {
    this.procurementCodesFacade.get(operationId);
  }

  getUsers(): void {
    this.usersFacade.get();
  }

  getTypeCategories(operationId: string): void {
    this.typeCategoryFacade.get(operationId);
  }

  getOperations(): void {
    this.operationsFacade.get();
  }

  getProcurementCodeDescriptionById(id: string): Observable<string> {
    return this.procurementCodesFacade.getProcurementCodeDescriptionById(id);
  }

  getGenderDescriptionById(id: string): Observable<string> {
    return this.gendersFacade.getGenderDescriptionById(id);
  }
}
