import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StockFlowReferenceDataDto } from '@stock-flow/models';
import { STOCK_FLOW_API_CONFIG } from '../../tokens';
import { SfReferenceDataTypeEnum } from '@stock-flow/models';
import {
  SelectListItemDto,
  SelectListItemWithTranslationDto
} from '@common/angular/core/models';

@Injectable()
export class StockFlowReferenceDataService {
  constructor(
    private http: HttpClient,
    @Inject(STOCK_FLOW_API_CONFIG) private apiUrl: string
  ) {}

  getSelectListByType(
    type: SfReferenceDataTypeEnum,
    operationId: string
  ): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<StockFlowReferenceDataDto[]>(
      `${this.apiUrl}/Reference/SelectLists/${type}?operationId=${operationId}`
    );
  }

  getUsers(): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(
      `${this.apiUrl}/Procurements/UserSelectList`
    );
  }

  getCountries(): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<StockFlowReferenceDataDto[]>(
      `${this.apiUrl}/Reference/Countries/SelectList/`
    );
  }

  getProcurementCodes(operationId: string): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<StockFlowReferenceDataDto[]>(
      `${this.apiUrl}/Reference/ProcurementCodes/SelectList?operationId=${operationId}`
    );
  }

  getOperations(): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<StockFlowReferenceDataDto[]>(
      `${this.apiUrl}/Reference/Operation/SelectList/`
    )
  }
}
