import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StockFlowReferenceDataService } from '@stock-flow/data-access';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { OperationsActions } from './operations.actions';
import { SelectListItemWithTranslationDto } from '@common/angular/core/models';

@Injectable()
export class OperationsEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationsActions.get),
      exhaustMap(() =>
        this.service.getOperations().pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) return OperationsActions.error;
            return OperationsActions.getSuccess({ items });
          }),
          catchError(() => of(OperationsActions.error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: StockFlowReferenceDataService
  ) {}
}
